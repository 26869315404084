import { stringify } from 'qs'

export const $apiPublic = $fetch.create({

  // Request interceptor
  async onRequest({ options }) {
    // Set baseUrl for all API calls
    options.baseURL = `${useRuntimeConfig().public.apiBaseUrl}/api/common`

    if (options.params) {
      const convertedOptions = new URLSearchParams(stringify(options.params))

      options.params = Object.fromEntries(convertedOptions)
    }
  },
})
